<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="用工单位" prop="company_id">
          <el-select v-model="table.params.company_id" placeholder="请选择" filterable @change="handleCompanyChange">
            <el-option
              v-for="item in companyOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="月份筛选" prop="date">
          <el-date-picker
            v-model="table.params.date"
            type="month"
            placeholder="选择月"
            format="yyyy 年 MM 月"
            value-format="yyyy-MM">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="员工名称" prop="name">
          <el-input clearable v-model.trim="table.params.name" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input clearable v-model.trim="table.params.phone" placeholder="请输入关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询
          </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd" style="display: flex;align-items: center;">
        <el-button :size="subUnitSize" type="primary" icon="el-icon-download" @click="handleExport">导出</el-button>
        <div style="margin-left: 10px;" v-if="all_total != 0"> 
          <span style="margin-right:20px;">单位部分：{{company_total}}元</span>
          <span style="margin-right:20px;">员工部分：{{employee_total}}元</span>
          <span style="margin-right:20px;">总计：{{all_total}}元</span>
        </div>
      </div>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :element-loading-text="table.loadingText"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import {exportData} from "@/util/index"
import { mapGetters } from 'vuex'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: '',
  mixins:[mixinTable],
  components: {
    VTable
  },
  computed: {
    ...mapGetters({
      companyOpt: 'companyList', // 获取企业列表
    })
  },
  data() {
    return {
      tableLoading: true,
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      field: [
        { name: "id", label: "ID"},
        { name: "company_name", label: "用工单位", width:"160", hidden: false },
        { name: "settlement_month", label: "缴纳月份", width:"120", hidden: false },
        { name: "employee_name", label: "员工姓名", hidden: false },
        { name: "phone", label: "联系电话", width:"120", hidden: false },
        { name: "salary", label: "应发工资/元", width:"120", hidden: false},
        { name: "insurance_level_name", label: "选择档次", width:"160", hidden: false},
        { name: "endowment_base", label: "养老保险基数", width:"120", hidden: false},
        { name: "company_endowment", label: "单位部分/元", width:"120",  hidden: false},
        { name: "employee_endowment", label: "员工部分/元", width:"120", hidden: false},
        { name: "medical_base", label: "医疗保险基数", width:"120", hidden: false},
        { name: "company_medical", label: "单位部分/元", width:"120",  hidden: false},
        { name: "employee_medical", label: "员工部分/元", width:"120", hidden: false},
        { name: "illness_base", label: "大病医疗保险基数", width:"140", hidden: false},
        { name: "company_illness", label: "单位部分/元", width:"120",  hidden: false},
        { name: "employee_illness", label: "员工部分/元", width:"120", hidden: false},
        { name: "unemployment_base", label: "失业保险基数", width:"120", hidden: false},
        { name: "company_unemployment", label: "单位部分/元", width:"120",  hidden: false},
        { name: "employee_unemployment", label: "员工部分/元", width:"120", hidden: false},
        { name: "birth_base", label: "生育险基数", width:"120", hidden: false},
        { name: "company_birth", label: "单位部分/元", width:"120",  hidden: false},
        { name: "injury_base", label: "工伤保险基数", width:"120", hidden: false},
        { name: "company_injury", label: "单位部分/元", width:"120",  hidden: false},
        { name: "extra_injury_base", label: "补充工伤保险", width:"120", hidden: false},
        { name: "company_extra_injury", label: "单位部分/元", width:"120",  hidden: false},
        { name: "company_insurance_fee", label: "单位总计", width:"120",  hidden: false},
        { name: "person_insurance_fee", label: "员工总计", width:"120",  hidden: false},
        { name: "created_at", label: "生成时间", width:"140", hidden: false},
      ],
      table: {
        loading: false,
        loadingText: '查询明细请先选择企业',
        params: {
          date: '',
          company_id: '',
          year: '',
          month: '',
          name: '',
          phone: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      selCurCompany: {},
      rowId: '',
      payType: {
        1: '单位全额',
        2: '员工全额',
        3: '单位员工各自缴纳'
      },
      company_total: '',
      employee_total: '',
      all_total: '',
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // payType(t){
    //   return {
    //     1: '单位全额',
    //     2: '员工全额',
    //     3: '单位员工各自缴纳'
    //   }[t]
    // },
    getTable() {
      if(this.table.params.date) {
        let _selDate = this.table.params.date
        this.table.params.year = this.$moment(_selDate).format('YYYY') || ''
        this.table.params.month = this.$moment(_selDate).format('MM') || ''
      } else if(this.table.params.date === null) {
        this.table.params.year = '';
        this.table.params.month = '';
      }
      this.$http.get('/admin/finance/declareInsuranceList', {params:this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.company_total = res.data.company_total;
          this.employee_total = res.data.employee_total;
          this.all_total = res.data.all_total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      if (!!row.id) {
        dom.getDetail(row)
      }
      dom = null
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            console.log('删除接口');
            done()
          } else {
            done()
          }
        }
      })
    },
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 重置
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.date = '';
      this.table.params.year = '';
      this.table.params.month = '';
      this.table.params.page = 1;
      this.getTable();
    },
    handleCompanyChange(id) {
      this.table.params.date = ''; // 选择企业清空上次企业的月份
      this.table.loading = false;
      this.companyfilter(id);
      this.getTable(); // 选择企业之后查询缴纳明细列表
    },
    // 根据企业带出企业福利
    companyfilter(id) {
      let arr =  this.companyOpt.filter(item => {
        return item.id == id;
      })
      this.selCurCompany = arr[0]
    },
    // 全部导出
    handleExport() {
      if(this.table.data.length > 0) {
        let _selDate = this.table.params.date
        let _params = {
          company_id: this.table.params.company_id,
          year: this.$moment(_selDate).format('YYYY') || '',
          month: this.$moment(_selDate).format('MM') || '',
          name: this.table.params.name,
          phone: this.table.params.phone,
          page: this.table.params.page,
          count: this.table.params.count,
        }
        console.log(_params);
        exportData(_params,'/admin/finance/exportDeclareInsurance')
      } else {
        this.$message.warning('没有需要导出的数据！')
      }
    },
  }
}
</script>
